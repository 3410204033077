import React from "react";
import Seo from "../components/seo/seo";
import Header from "../components/header/header";

import * as styles from "../scss/pages/chrzanolandia.module.scss";

const ChrzanolandiaPage = () => {
	return (
		<>
			<Header />
			<Seo title="Chrzanolandia" description="" image="" />

			<section className={styles.main}>
				<div className="container">
					<h1>Chrzanolandia</h1>
					<div className={styles.logos}>
						<a
							href="http://www.chrzanolandia.com.pl/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="/img/chrzanolandia.svg"
								width={195}
								alt="Chrzanolandia"
								loading="lazy"
							/>
						</a>
						<a
							href="http://www.partnerstwonajurze.pl/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="/img/jura.svg"
								width={90}
								alt="Partnerstwo na jurze"
								loading="lazy"
							/>
						</a>
					</div>

					<div className={styles.copy}>
						<p>
							Lokalna Grupa Działania „Partnerstwo na Jurze”
							stworzyła markę powiatu chrzanowskiego pod nazwą
							„Chrzanolandia”, skupiającą największe atrakcje
							turystyczne gmin tego terenu.
						</p>

						<p>
							W ramach marki „Chrzanolandia” organizowanych jest
							wiele wydarzeń kulturalnych, jarmarków, konkursów,
							ponadto LGD realizuje projekty mające na celu wzrost
							zainteresowania kulturą i tradycją regionu.
						</p>

						<h2>Najciekawsze lokalne produkty turystyczne:</h2>
						<ul>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-110-osrodek_rekreacyjno_wypoczynkowy_w.html"
									target="_blank"
								>
									Ośrodek Rekreacyjno – Wypoczynkowy w Zagórzu
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-132-osrodek_wypoczynkowy_skowronek.html"
									target="_blank"
								>
									Ośrodek wypoczynkowy "Skowronek"
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-116-skrzatolandia.html"
									target="_blank"
								>
									Skrzatolandia
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-157-bajlandia.html"
									target="_blank"
								>
									Bajlandia
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-156-red_raj_relaksowisko_gospodarstwo.html"
									target="_blank"
								>
									Red Raj relaksowisko gospodarstwo
									agroturystyczne
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-115-metkow_gospodarstwo_ekologiczne_mariusz.html"
									target="_blank"
								>
									Mętków - Gospodarstwo ekologiczne Mariusz
									Tatar
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-163-osrodek_wypoczynkowy_chechlo_i_osrodek.html"
									target="_blank"
								>
									Ośrodek Wypoczynkowy "Chechło" i Ośrodek
									Wypoczynkowy Miejskiego Klubu Sportów
									Wodnych „Trzebinia”
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-169-kwaczala_trasy_nordic_walking.html"
									target="_blank"
								>
									Kwaczała - trasy Nordic walking
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-226-stawy_kaskadowe_w_pogorzycach.html"
									target="_blank"
								>
									Stawy Kaskadowe w Pogorzycach
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-227-basen_odkryty_w_libiazu.html"
									target="_blank"
								>
									Basen odkryty w Libiążu
								</a>
							</li>
						</ul>

						<h2>Lista Potraw Tradycyjnych:</h2>
						<ul>
							<li>Buchta bolęcińska</li>
							<li>Hulajdy</li>
							<li>Mirowska parzybroda</li>
						</ul>
						<p>
							Pełną broszurę znajdą państwo{" "}
							<a
								href="https://drive.google.com/file/d/1J43EKjcrrWMIBhjHmqfzFP6AmlMcqr_x/view"
								target="_blank"
							>
								TUTAJ
							</a>
							.
						</p>

						<h2>Najciekawsze wydarzenia kulturalne:</h2>
						<ul>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-54-chrzanolandia_zaprasza_na_s.html"
									target="_blank"
								>
									Ziemniaczysko
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-55-szanty_na_wisle.html"
									target="_blank"
								>
									Szanty na Wiśle
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-178-jarmark_wielkanocny.html"
									target="_blank"
								>
									Jarmark wielkanocny
								</a>
							</li>
							<li>
								<a
									href="http://www.chrzanolandia.com.pl/strona-234-pociag_do_malopolski.html"
									target="_blank"
								>
									Pociąg do Małopolski
								</a>
							</li>
						</ul>

						<p>
							Mapa z najciekawszymi miejscami:{" "}
							<a
								href="http://www.chrzanolandia.com.pl/mapa.html"
								target="_blank"
							>
								MAPA
							</a>
							.
						</p>
					</div>
				</div>
				<img
					className={styles.wood}
					src="/img/footer-wood.png"
					alt="Drewno"
					loading="lazy"
				/>
			</section>
		</>
	);
};

export default ChrzanolandiaPage;
